<template>
  <div>
    <b-row class="justify-content-between mb-2">
      <b-col lg="6">
        <b-form-group
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="advancedFilter.search"
              type="search"
              placeholder="Search by name"
            />
            <b-input-group-append>
              <b-button
                :disabled="!advancedFilter.search"
                @click="advancedFilter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-button
          v-b-modal.tagModal
          @click="onCreateClick"
        >
          Create tag
        </b-button>
      </b-col>
    </b-row>
    <b-modal
      id="tagModal"
      :title="title"
      hide-footer
    >
      <tag-form
        :tag="tagEdit"
        @submit="onEditSubmit()"
      />
    </b-modal>
    <data-table
      :module-name="'tagsList'"
      :fields="fields"
      :filters="advancedFilter"
      sort-by="createdAt"
      :sort-desc="true"
      :actions="actions"
      deletable
      @edit="onEdit"
    >
      <template #cell(name)="data">
        <b-badge :variant="data.item.color !== 'dark' ? `light-${data.item.color}` : data.item.color">
          {{ data.item.name }}
        </b-badge>
      </template>

      <template #cell(blocking)="data">
        <b-badge :variant="data.item.blocking ? 'light-danger' : 'light-info'">
          {{ data.item.blocking ? 'blocking' : 'nonblocking' }}
        </b-badge>
      </template>

      <template #cell(blocking_time)="data">
        {{ data.item.blocking_time }}s
      </template>
    </data-table>
  </div>
</template>
<script>
import {
  BButton,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BRow,
  BBadge,
} from 'bootstrap-vue'
import { get } from 'vuex-pathify'
import { isEmpty } from 'lodash'
import DataTable from '@/components/ui/tables/DataTable.vue'
import { formatDateTime } from '@/plugins/formaters'
import TagForm from '@/components/forms/tag/TagForm.vue'

const actions = [
  {
    text: 'Edit',
    icon: 'EditIcon',
    emit: 'edit',
  },
]

const fields = [
  {
    key: 'name',
    sortable: true,
  },
  {
    key: 'color',
    sortable: true,
  },
  {
    key: 'description',
    sortable: false,
  },
  {
    key: 'blocking',
    sortable: false,
  },
  {
    key: 'blocking_time',
    sortable: false,
  },
]
export default {
  components: {
    DataTable,
    TagForm,
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BRow,
    BBadge,
  },
  data() {
    return {
      fields,
      actions,
      advancedFilter: { search: '' },
      tagEdit: null,
      title: '',
    }
  },
  computed: {
    ...get('tagsList', ['filters']),
  },
  beforeMount() {
    if (!isEmpty(this.filters)) {
      this.advancedFilter = { ...this.filters }
    }
  },
  methods: {
    formatDateTime,
    onEdit(row) {
      this.title = 'Tag edit'
      this.tagEdit = row
      this.$bvModal.show('tagModal')
    },
    onEditSubmit() {
      this.$bvModal.hide('tagModal')
    },
    onCreateClick() {
      this.tagEdit = null
      this.title = 'Tag create'
    },
  },
}
</script>
