<template>
  <entity-form
    :id="tag ? tag.id : null"
    module-name="tagsList"
    :model="form"
    @submit="onSubmit"
  >
    <b-form-group
      label="Name"
    >
      <b-form-input
        v-model="form.name"
        placeholder="Name"
      />
    </b-form-group>

    <b-form-group
      label="Description"
    >
      <b-form-input
        v-model="form.description"
        placeholder="Description"
      />
    </b-form-group>

    <b-form-group>
      <b-form-checkbox
        v-model="form.blocking"
        class="my-1"
      >
        Block push to next phase
      </b-form-checkbox>
    </b-form-group>

    <b-form-group
      label="Blocking time in seconds"
    >
      <b-form-input
        v-model.number="form.blocking_time"
        :disabled="!form.blocking"
        type="number"
        :min="0"
        placeholder="0"
      />
    </b-form-group>

    <b-form-group>
      <b-form-checkbox
        v-model="form.visible"
        class="my-1"
      >
        Tag will be visible:
      </b-form-checkbox>
    </b-form-group>

    <b-form-group
      label="Color"
    >
      <b-form-input
        v-model="form.color"
        placeholder="Color"
      />
      <b-badge
        v-for="(option, index) in options"
        :key="index"
        :variant="option"
        size="xl"
        class="ml-1 mt-1 p-1"
        @click="form.color = option"
      >
        <strong>{{ option }}</strong>
      </b-badge>
    </b-form-group>
  </entity-form>
</template>

<script>
import {
  BFormGroup, BFormInput, BBadge, BFormCheckbox,
} from 'bootstrap-vue'
import EntityForm from '@/components/ui/forms/EntityForm.vue'

const options = [
  'primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark',
]

export default {
  components: {
    EntityForm,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BBadge,
  },
  props: {
    tag: {
      required: false,
      type: Object,
    },
  },
  data() {
    return {
      form: {
        name: this.tag ? this.tag.name : null,
        color: this.tag ? this.tag.color : null,
        description: this.tag ? this.tag.description : '',
        blocking: this.tag ? this.tag.blocking : true,
        visible: this.tag ? this.tag.visible : true,
        blocking_time: this.tag ? this.tag.blocking_time : 0,
      },
      options,
    }
  },
  methods: {
    onSubmit(data) {
      this.$emit('submit', data)
    },
  },
}
</script>

<style scoped>

</style>
